import React, { useEffect, useState } from 'react'

const pic = '/HappyBirthday/birthdayPic.jpeg'
const song = '/HappyBirthday/birthdaySong.mp3'

const HappyBirthday = () => {
    const [answer, setAnswer] = useState('')
    const [inputValue, setInputValue] = useState('')
    const [playing, setPlaying] = useState(false)
    const [won, setWon] = useState(false)

    const [loading, setLoading] = useState(true)
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        const fetchWordleAnswer = async () => {
            try {
                // Get today's date in the format YYYY-MM-DD
                const today = new Date()
                const year = today.getFullYear()
                const month = String(today.getMonth() + 1).padStart(2, '0')
                const day = String(today.getDate()).padStart(2, '0')
                const todayDate = `${year}-${month}-${day}`

                // Construct the API URL
                const url = `https://www.nytimes.com/svc/wordle/v2/${todayDate}.json`
                console.log(url)

                // Fetch data from the API
                const response = await fetch(url)

                if (!response.ok) {
                    throw new Error(`Failed to fetch data: ${response.statusText}`)
                }

                const data = await response.json()

                // Get the Wordle answer and capitalize it
                setAnswer(data.solution.toUpperCase().trim())
            } catch (err) {
                console.error(err)
                setErrorMessage('Failed to fetch the Wordle answer. Please try again later.')
                setShowErrorModal(true)
            } finally {
                setLoading(false)
            }
        }

        fetchWordleAnswer()
    }, [])

    const handleInputChange = (e) => {
        const value = e.target.value.toUpperCase().trim()
        if (/^[A-Z]{0,5}$/.test(value)) {
            setInputValue(value)

            if (value === answer) {
                setPlaying(false)
                setInputValue('')
                setWon(true)
            }
        }
    }

    // React.useEffect(() => {
    //     if (playing) {
    //         const audio = new Audio(song)
    //         audio.play()

    //         return () => {
    //             audio.pause()
    //             audio.currentTime = 0
    //         }
    //     }
    // }, [playing])

    return (
        <div className="flex flex-col items-center justify-center bg-gray-900 h-screen text-white pt-10">
            <h1 className="text-2xl font-bold">{won ? '🎊 Happy Birthday You Win! 🎊' : '🎊 Happy Birthday! 🎊'}</h1>
            {(loading || errorMessage.length) && (
                <div className="my-10">
                    <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                </div>
            )}

            {!loading && !playing && !errorMessage.length && (
                <button
                    onClick={() => setPlaying(true)}
                    className="text-lg font-medium bg-blue-600 hover:bg-blue-500 text-white px-6 py-3 my-10 rounded-lg cursor-pointer">
                    Play Game
                </button>
            )}

            {playing && !loading && (
                <div className="flex flex-col items-center my-10">
                    <h1 className="text-lg font-semibold mb-4">
                        To stop playing...what is the answer to today's Wordle?
                    </h1>
                    <input
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder="Answer Here"
                        className="w-72 h-10 text-center rounded-md bg-gray-200 text-lg mb-4 outline-none"
                    />
                </div>
            )}

            <div className="mt-6">
                <img
                    src={pic}
                    alt="birthday"
                    className="max-w-[220px] h-auto mb-6 rounded-md border-2 border-white"
                />
            </div>

            {/* Error Modal */}
            {showErrorModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75">
                    <div className="bg-white rounded-lg p-6 text-center">
                        <h2 className="text-xl font-bold text-red-600 mb-4">Error</h2>
                        <p className="text-gray-700 mb-6">{errorMessage}</p>
                        <button
                            onClick={() => setShowErrorModal(false)}
                            className="bg-blue-600 hover:bg-blue-500 text-white px-4 py-2 rounded-lg">
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default HappyBirthday
