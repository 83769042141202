import React, { useEffect, useRef, useState } from 'react'

const lost = '/TrumpShot/lost.webp'
const obama = '/TrumpShot/obama.png'
const obamaDap = '/TrumpShot/obamaDap.gif'
const shot = '/TrumpShot/shot.png'
const gunshotSound = '/TrumpShot/shotSound.mp3'
const head = '/TrumpShot/trump.png'
const win = '/TrumpShot/win.webp'

const TrumpShot = () => {
    const [gameState, setGameState] = useState('playing') // 'playing', 'won', 'lost'
    const [imageVisible, setImageVisible] = useState(false)
    const [imagePosition, setImagePosition] = useState({ top: 0, left: 0 })
    const [image2Position, setImage2Position] = useState({ top: 0, left: 0 })
    const [startTime, setStartTime] = useState(0)
    const [timer, setTimer] = useState(0)
    const [score, setScore] = useState(0)
    const [record, setRecord] = useState(0)
    const intervalIdRef = useRef(null)

    const containerRef = useRef(null)
    const [showShot, setShowShot] = useState(false)
    const [shotPosition, setShotPosition] = useState({ x: 0, y: 0 })

    useEffect(() => {
        if (gameState === 'playing') {
            const timer = setTimeout(() => {
                const top = Math.random() * 80 + 10
                const left = Math.random() * 80 + 10
                const top2 = top + Math.random() * 80 + 10
                const left2 = left + Math.random() * 80 + 10
                setImagePosition({ top, left })
                setImageVisible(true)
                setImage2Position({ top2, left2 })
                const start = Date.now()
                setStartTime(start)
                intervalIdRef.current = setInterval(() => {
                    setTimer(Date.now() - start)
                }, 1)
            }, Math.random() * 4000)

            return () => clearTimeout(timer)
        }
    }, [gameState])

    const handleGunShot = (event) => {
        const rect = containerRef.current.getBoundingClientRect()
        const { clientX, clientY } = event
        const x = clientX - rect.left - 130 // Adjust x to position slightly to the left
        const y = clientY - rect.top - 100
        setShotPosition({ x, y })
        setShowShot(true)
        setTimeout(() => setShowShot(false), 100)

        const audio = new Audio(gunshotSound)
        audio.volume = 0.25
        audio.play()
    }

    const handleClick = () => {
        if (!imageVisible || gameState !== 'playing') return

        clearInterval(intervalIdRef.current)
        setTimer(Date.now() - startTime)

        if (Date.now() - startTime <= 1000) {
            setGameState('won')
            setScore(score + 1)
        } else {
            setGameState('lost')
            if (score > record) {
                setRecord(score)
            }
            setScore(0)
        }
    }

    const handleClickBad = () => {
        if (!imageVisible || gameState !== 'playing') return

        clearInterval(intervalIdRef.current)
        setTimer(Date.now() - startTime)

        setGameState('lostBad')
        if (score > record) {
            setRecord(score)
        }
        setScore(0)
    }

    const handleReset = () => {
        if (intervalIdRef.current) clearInterval(intervalIdRef.current)

        setGameState('playing')
        setImageVisible(false)
        setStartTime(0)
        setTimer(0)
    }

    const renderGameState = () => {
        switch (gameState) {
            case 'won':
                return (
                    <div className="flex flex-col items-center pt-12 text-green-400">
                        <h2 className="text-xl mb-4">
                            {score === 1 && 'Nice shot! You saved America!'}
                            {score === 2 && 'Am I gonna get in trouble for this game?'}
                            {score === 3 && "Let's make it worse"}
                            {score === 4 && 'Wow you are on a roll!'}
                            {score === 5 && 'Yeah secret service is definitely showing up.'}
                            {score > 5 && 'Nice shot!'}
                        </h2>
                        <img
                            src={win}
                            alt="Win"
                            className="w-72 h-72"
                        />
                        <button
                            onClick={handleReset}
                            className="mt-5 px-6 py-2 bg-gray-600 text-white rounded-lg text-lg">
                            Round {score + 1}
                        </button>
                    </div>
                )
            case 'lost':
                return (
                    <div className="flex flex-col items-center pt-12 text-red-400">
                        <h2 className="text-xl mb-4">You failed!</h2>
                        <img
                            src={lost}
                            alt="Lost"
                            className="w-72 h-56"
                        />
                        <button
                            onClick={handleReset}
                            className="mt-5 px-6 py-2 bg-gray-600 text-white rounded-lg text-lg">
                            Restart
                        </button>
                    </div>
                )
            case 'lostBad':
                return (
                    <div className="flex flex-col items-center pt-12 text-red-500">
                        <h2 className="text-xl mb-4">Dude, you shot Obama... Not cool</h2>
                        <img
                            src={obamaDap}
                            alt="Obama Dap"
                            className="w-72 h-72"
                        />
                        <button
                            onClick={handleReset}
                            className="mt-5 px-6 py-2 bg-gray-600 text-white rounded-lg text-lg">
                            Restart
                        </button>
                    </div>
                )
            default:
                return null
        }
    }

    return (
        <div className="flex flex-col items-center text-white bg-gray-800 h-screen pt-16 relative select-none">
            <h2 className="text-2xl font-bold">GET DOWN MR. (ex) PRESIDENT</h2>
            <h4 className="font-light">Shoot the target within 1 second</h4>
            <div
                ref={containerRef}
                onClick={handleGunShot}
                className="relative flex flex-col items-center bg-gray-800 h-[80%] w-full pt-8">
                {showShot && (
                    <img
                        src={shot}
                        alt="Gunshot"
                        className="absolute pointer-events-none"
                        style={{ top: `${shotPosition.y}px`, left: `${shotPosition.x}px` }}
                    />
                )}
                <div className="text-lg">
                    <span className="mr-5">Personal Record: {record}</span>|
                    <span className="mx-5">{(timer / 1000).toFixed(3)}s</span>|
                    <span className="ml-5">
                        {score > 0 ? 'Dead Racists' : 'Consecutive Wins'}: {score}
                    </span>
                </div>
                {gameState === 'playing' && (
                    <div onClick={handleClick}>
                        <img
                            src={head}
                            alt="Target"
                            className="absolute w-24 h-24 select-none [-webkit-user-drag:none]"
                            style={{
                                top: `${imagePosition.top}%`,
                                left: `${imagePosition.left}%`,
                                display: imageVisible ? 'inline' : 'none',
                            }}
                        />
                    </div>
                )}
                {gameState === 'playing' && score >= 3 && (
                    <div onClick={handleClickBad}>
                        <img
                            src={obama}
                            alt="Target"
                            className="absolute w-36 h-28 select-none [-webkit-user-drag:none]"
                            style={{
                                top: `${image2Position.top}%`,
                                left: `${image2Position.left}%`,
                                display: imageVisible ? 'inline' : 'none',
                            }}
                        />
                    </div>
                )}
                {renderGameState()}
            </div>
        </div>
    )
}

export default TrumpShot
