import React from 'react'

import { Link } from 'react-router-dom'

const routes = [
    { path: '/price-guess', label: 'Price Is Kinda Right', img: '/price-is-right.png' },
    { path: '/happy-birthday', label: 'Happy Birthday', img: '/happy-birthday.png' },
    { path: '/addy-muncher', label: 'Addy Muncher', img: '/addy-muncher.png' },
    { path: '/trump-shot', label: 'Trump Shot', img: '/trump-shot.png' },
    { path: '/dungeon-adventure', label: 'Dungeon Adventure', img: '/dungeon-adventure.png' },
    { path: '/minion-catch', label: 'Click the Minion', img: '/minion-catch.png' },
    { path: '/rock-paper-scissors', label: 'Rock Paper Scissors', img: '/rock-paper-scissor.png' },
    { path: '/day-one', label: 'Day One', img: '/day-one.png' },
]

const Home = () => {
    return (
        <div className="flex flex-col w-full items-center">
            <div className="flex flex-col justify-center items-center py-10">
                <img
                    src="/tseten.jpg"
                    alt="Tseten"
                    className="h-28 w-28 md:w-32 md:h-32 object-cover rounded-full border-2 border-gray-800 mb-3 transform transition-transform duration-500 hover:scale-125"
                />
                <Link className="text-5xl text-gray-700 font-moresugar font-light">tseten.fun</Link>
            </div>
            <div className="min-h-[80vh] w-[90vw] md:w-[85vw] lg:w-[80vw] grid lg:grid-cols-3 content-start gap-8 py-8">
                {routes.map((route, index) => (
                    <Link
                        to={route.path}
                        key={index}
                        className="border-[3px] border-gray-900 rounded-2xl overflow-hidden h-48 lg:h-40 flex flex-col items-center justify-center text-center hover:bg-gray-100 transform transition-transform duration-300 hover:scale-105">
                        <img
                            src={route.img}
                            alt={route.label}
                            className="w-full h-full object-cover"
                        />
                    </Link>
                ))}
            </div>
            <footer className="mt-auto flex flex-col items-center py-8">
                {/* Divider */}
                <div className="w-16 border-t-2 border-gray-400 mb-8"></div>
                {/* Footer Text */}
                <p className="text-lg text-gray-700">Last updated: Dec 10, 2024</p>
            </footer>
        </div>
    )
}

export default Home
