import React, { useEffect, useRef, useState } from 'react'

const minionPng = '/minion.png'

const MinionCatch = () => {
    const [position, setPosition] = useState({ top: 50, left: 50 })
    const containerRef = useRef(null)

    useEffect(() => {
        const handleMouseMove = (event) => {
            const container = containerRef.current
            if (container) {
                const containerRect = container.getBoundingClientRect()
                const containerWidth = containerRect.width
                const containerHeight = containerRect.height
                const imageWidth = 100 // Adjust based on your image size
                const imageHeight = 100 // Adjust based on your image size

                const mouseX = event.clientX - containerRect.left
                const mouseY = event.clientY - containerRect.top

                const minDistance = 200 // Increased distance to start avoiding the cursor

                const deltaX = position.left - mouseX
                const deltaY = position.top - mouseY
                const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY)

                if (distance < minDistance) {
                    let newTop = Math.random() * (containerHeight - imageHeight)
                    let newLeft = Math.random() * (containerWidth - imageWidth)

                    // Ensure the image doesn't go out of the container
                    newTop = Math.max(0, Math.min(newTop, containerHeight - imageHeight))
                    newLeft = Math.max(0, Math.min(newLeft, containerWidth - imageWidth))

                    setPosition({ top: newTop, left: newLeft })
                }
            }
        }

        const container = containerRef.current
        if (container) {
            container.addEventListener('mousemove', handleMouseMove)
        }

        return () => {
            if (container) {
                container.removeEventListener('mousemove', handleMouseMove)
            }
        }
    }, [position])

    return (
        <div
            className="relative flex flex-col items-center justify-start bg-gray-900 text-white h-screen pt-10"
            ref={containerRef}>
            <h1 className="text-3xl font-bold mb-5">Catch the minion or you have no bitches</h1>
            <div
                className="absolute transition-all duration-200 ease-in"
                style={{
                    top: `${position.top}px`,
                    left: `${position.left}px`,
                }}>
                <img
                    src={minionPng}
                    alt="Minion"
                    className="w-14 h-14"
                />
            </div>
        </div>
    )
}

export default MinionCatch
