import React, { useEffect, useState } from 'react'

const objectImg = '/AddyMuncher/addy.webp'
const specialObjectImg = '/AddyMuncher/budak.png'
const chaserImg = '/AddyMuncher/chaser.webp'
const pacmanImg = '/AddyMuncher/pacman.gif'

const GRID_SIZE = 20
const INITIAL_PACMAN_POSITION = { x: 1, y: 1 }
const INITIAL_DIRECTION = 'ArrowDown'
const INITIAL_SPEED = 300
const INITIAL_CHASER_SPEED = 300

const getRandomPositionOnLastRow = () => ({
    x: Math.floor(Math.random() * GRID_SIZE),
    y: GRID_SIZE - 1,
})

const getRandomPosition = () => ({
    x: Math.floor(Math.random() * GRID_SIZE),
    y: Math.floor(Math.random() * GRID_SIZE),
})

const Modal = ({ onRestart }) => (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-8 rounded-md text-center shadow-lg">
            <h2 className="text-2xl font-bold text-black mb-4">You Lost!</h2>
            <button
                onClick={onRestart}
                className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-400">
                Restart
            </button>
        </div>
    </div>
)

const PacmanGame = () => {
    const [pacmanPosition, setPacmanPosition] = useState(INITIAL_PACMAN_POSITION)
    const [direction, setDirection] = useState(INITIAL_DIRECTION)
    const [objectPosition, setObjectPosition] = useState(getRandomPosition())
    const [specialObjectPosition, setSpecialObjectPosition] = useState(null)
    const [chaserPosition, setChaserPosition] = useState(getRandomPositionOnLastRow())
    const [chaserSpeed, setChaserSpeed] = useState(INITIAL_CHASER_SPEED)
    const [score, setScore] = useState(0)
    const [speed, setSpeed] = useState(INITIAL_SPEED)
    const [isLarge, setIsLarge] = useState(false)
    const [gameOver, setGameOver] = useState(false)

    const resetGame = () => {
        setPacmanPosition(INITIAL_PACMAN_POSITION)
        setDirection(INITIAL_DIRECTION)
        setObjectPosition(getRandomPosition())
        setSpecialObjectPosition(null)
        setChaserPosition(getRandomPositionOnLastRow())
        setChaserSpeed(INITIAL_CHASER_SPEED)
        setScore(0)
        setSpeed(INITIAL_SPEED)
        setIsLarge(false)
        setGameOver(false)
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                e.preventDefault()
                setDirection(e.key)
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    useEffect(() => {
        if (gameOver) return

        const movePacman = () => {
            setPacmanPosition((prevPosition) => {
                let newPosition
                switch (direction) {
                    case 'ArrowUp':
                        newPosition = {
                            x: prevPosition.x,
                            y: Math.max(prevPosition.y - 1, 0),
                        }
                        break
                    case 'ArrowDown':
                        newPosition = {
                            x: prevPosition.x,
                            y: Math.min(prevPosition.y + 1, GRID_SIZE - 1),
                        }
                        break
                    case 'ArrowLeft':
                        newPosition = {
                            x: Math.max(prevPosition.x - 1, 0),
                            y: prevPosition.y,
                        }
                        break
                    case 'ArrowRight':
                        newPosition = {
                            x: Math.min(prevPosition.x + 1, GRID_SIZE - 1),
                            y: prevPosition.y,
                        }
                        break
                    default:
                        newPosition = prevPosition
                }

                if (newPosition.x === objectPosition.x && newPosition.y === objectPosition.y) {
                    setScore(score + 1)
                    setObjectPosition(getRandomPosition())
                    setSpeed((prevSpeed) => Math.max(prevSpeed - 10, 100))

                    if ((score + 1) % 5 === 0) {
                        setSpecialObjectPosition(getRandomPosition())
                    }
                }

                if (
                    specialObjectPosition &&
                    newPosition.x === specialObjectPosition.x &&
                    newPosition.y === specialObjectPosition.y
                ) {
                    setSpecialObjectPosition(null)
                    setIsLarge(true)
                    setSpeed((prevSpeed) => prevSpeed * 2)
                    setTimeout(() => {
                        setIsLarge(false)
                        setSpeed((prevSpeed) => prevSpeed / 2)
                    }, 8000)
                }

                return newPosition
            })
        }

        const moveChaser = () => {
            setChaserPosition((prevPosition) => {
                let { x, y } = prevPosition
                if (x < pacmanPosition.x) x++
                else if (x > pacmanPosition.x) x--
                if (y < pacmanPosition.y) y++
                else if (y > pacmanPosition.y) y--

                if (x === pacmanPosition.x && y === pacmanPosition.y) {
                    setGameOver(true)
                }

                return { x, y }
            })
        }

        const batchInterval = setInterval(() => {
            moveChaser()
            movePacman()
        }, speed)

        return () => {
            clearInterval(batchInterval)
        }
    }, [direction, speed, gameOver])

    const renderGrid = React.useCallback(() => {
        const grid = []
        for (let y = 0; y < GRID_SIZE; y++) {
            for (let x = 0; x < GRID_SIZE; x++) {
                const isPacman = pacmanPosition.x === x && pacmanPosition.y === y
                const isObject = objectPosition.x === x && objectPosition.y === y
                const isSpecialObject =
                    specialObjectPosition && specialObjectPosition.x === x && specialObjectPosition.y === y
                const isChaser = chaserPosition.x === x && chaserPosition.y === y

                grid.push(
                    <div
                        key={`${x}-${y}`}
                        className={`w-6 h-6 border border-gray-700 flex items-center justify-center`}>
                        {isPacman && (
                            <img
                                src={pacmanImg}
                                alt="Pacman"
                                className={`w-full h-full ${isLarge ? 'scale-125' : ''}`}
                            />
                        )}
                        {isObject && (
                            <img
                                src={objectImg}
                                alt="Object"
                                className="w-full h-full"
                            />
                        )}
                        {isSpecialObject && (
                            <img
                                src={specialObjectImg}
                                alt="Special Object"
                                className="w-full h-full"
                            />
                        )}
                        {isChaser && (
                            <img
                                src={chaserImg}
                                alt="Chaser"
                                className="w-full h-full"
                            />
                        )}
                    </div>
                )
            }
        }
        return grid
    }, [pacmanPosition, chaserPosition, specialObjectPosition])

    return (
        <div className="flex flex-col items-center bg-gray-900 text-white min-h-screen">
            <h1 className="text-3xl font-bold my-4">Addy Muncher</h1>
            <p className="text-lg mb-4">Geeked Score: {score}</p>
            <div
                className="grid"
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${GRID_SIZE}, 1.5rem)`,
                    gap: '0.25rem',
                }}>
                {renderGrid()}
            </div>
            {gameOver && <Modal onRestart={resetGame} />}
        </div>
    )
}

export default PacmanGame
