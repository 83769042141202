import React, { useState } from 'react'

const boatFall = '/DungeonAdventure/boatFall.webp'
const brooklynMirage = '/DungeonAdventure/brooklynMirage.webp'
const candySteal = '/DungeonAdventure/candySteal.png'
const childCandy = '/DungeonAdventure/childCandy.webp'
const converse = '/DungeonAdventure/converse.webp'
const dad = '/DungeonAdventure/dad.webp'
const guardian = '/DungeonAdventure/guardian.webp'
const gunna = '/DungeonAdventure/gunna.webp'
const hole = '/DungeonAdventure/hole.webp'
const holeFail = '/DungeonAdventure/holeFail.webp'
const completeGif = '/DungeonAdventure/rickRoll.gif'
const roll = '/DungeonAdventure/roll.mp3'
const soulja = '/DungeonAdventure/soulja.webp'
const souljaFail = '/DungeonAdventure/souljaKill.jpeg'

const scenes = {
    Scene1: {
        text: "You find yourself at the entrance of an ancient dungeon guarded by a mystical creature.\n\nThe guardian speaks: 'To enter, you must give up your first-born son. Will you do it?'",
        options: [
            { text: 'Never!', nextScene: 'Scene2A' },
            { text: 'Fine...', nextScene: 'GameOver1' },
        ],
    },
    Scene2A: {
        text: "Impressed by your resolve, the guardian allows you to pass.\n\nYou enter the dungeon and soon find yourself at the edge of an underground lake. There are two boats: one named 'Gunna' and the other 'Don Toliver'.\n\nWhich boat do you choose?",
        options: [
            { text: 'Don Toliver', nextScene: 'GameOver2' },
            { text: 'Gunna', nextScene: 'Scene3A' },
        ],
    },
    Scene3A: {
        text: "You row across the lake in the 'Gunna' boat, feeling a sense of determination.\n\nOn the other side, you encounter an unkempt, homeless child holding a piece of candy. The child looks up at you and asks, 'Please ma'am, may I have some food?'\n\nYou dig through your tote-bag to grab some cash, but you realize your emergency candy stash is dangerously low. Do you 'Give' money or 'Take' his candy?",
        options: [
            { text: 'Give (Money)', nextScene: 'Scene4A' },
            { text: 'Take (Candy)', nextScene: 'GameOver3' },
        ],
    },
    Scene4A: {
        text: "The child gratefully accepts the money and thanks you profusely.\n\nHe then wordlessly points you towards a hidden passage. You follow the passage and find yourself walking towards a man hidden by shadows.\n\nAs you walk towards the man, light shines on his face—holy fuck it's Soulja Boy. What is the first thing you say to him?",
        options: [
            { text: 'Hi', nextScene: 'GameOver4' },
            { text: 'Yuuuuuuu', nextScene: 'Scene5A' },
        ],
    },
    Scene5A: {
        text: "Soulja Boy daps you up and says, 'I didn't know you were chill like that'. He lets you pass.\n\nYou reach the end of the passage and find a ladder extending from a dark hole on the ground, all the way through a light-filled hole in the ceiling.\n\nWhich direction do you climb?",
        options: [
            { text: 'Up', nextScene: 'Scene6A' },
            { text: 'Down', nextScene: 'GameOver5' },
        ],
    },
    Scene6A: {
        text: "You painstakingly climb up the ladder (it takes a while because you've been skipping the gym).\n\nAs you near the top of the ladder, you hear music playing...it's EDM!!! Your head pops out of the hole, and you find yourself at...Brooklyn Mirage? What the fuck...",
        options: [
            {
                text: "Don't question anything & partake in the Rave",
                nextScene: 'Success',
            },
            { text: 'Climb back down', nextScene: 'GameOver6' },
        ],
        image: brooklynMirage,
    },
    Success: {
        text: "You join the rave without a second thought. You see your friends walk up to you.\n\n'Are you okay?' they ask.\n\n'Uhhh...yeah? I just went through this crazy adventure and ended up here...' you reply.\n\n'...What are you talking about? You were just spacing out for a minute there...' they say. \n\n'No, no. I just came out of that dungeon.' you say with a puzzled expression. \n\nYou turn to point at the hole you came out of, but it mysteriously disappeared...\n\nTurns out the entire dungeon adventure experience was just a Molly and Adderall fueled hallucination.",
        options: [{ text: 'See Your Score', nextScene: 'Complete' }],
    },
    Complete: {
        text: 'Your Choices: \n\n Never + \n\nGunna Give + \n\nYuuuu + \n\nUp.',
        options: [{ text: 'Restart', nextScene: 'Scene1' }],
    },
    GameOver1: {
        text: "The guardian shakes its head in disappointment. 'A true adventurer never gives up what they hold dear,' it says as the ground beneath you opens up and you fall into an endless pit.\n\nGame Over.",
        options: [{ text: 'Restart', nextScene: 'Scene1' }],
    },
    GameOver2: {
        text: "As you step into the 'Don Toliver' boat, you hear the soulful tunes of Don Toliver's music filling the air.\n\nThe music, though enchanting, lulls you into a deep, irreversible sleep. You drift off and never wake up. It seems the choice wasn't just about the boat, but the music that accompanied it.\n\nGame Over.",
        options: [{ text: 'Restart', nextScene: 'Scene1' }],
    },
    GameOver3: {
        text: 'What is wrong with you? Big bodied as fu-...\n\nGame Over.',
        options: [{ text: 'Restart', nextScene: 'Scene1' }],
    },
    GameOver4: {
        text: "Soulja Boy cranks up a nine and shoots you with it. You bleed out as he 'supermans' over your dead body.\n\nGame Over.",
        options: [{ text: 'Restart', nextScene: 'Scene1' }],
    },
    GameOver5: {
        text: "You head down the hole and find your dad waiting for you. You groan as he starts bitchin' at you.\n\nGame Over.",
        options: [{ text: 'Restart', nextScene: 'Scene1' }],
    },
    GameOver6: {
        text: 'Pussy.\n\nGame Over.',
        options: [{ text: 'Restart', nextScene: 'Scene1' }],
    },
}

const TextAdventureGame = () => {
    const audio = new Audio(roll)
    const [currentScene, setCurrentScene] = useState('Scene1')

    const handleOptionClick = (nextScene) => {
        setCurrentScene(nextScene)
    }

    React.useEffect(() => {
        if (currentScene === 'Complete') {
            audio.play()
        } else {
            audio.pause()
            audio.currentTime = 0
        }

        return () => {
            audio.pause()
            audio.currentTime = 0
        }
    }, [currentScene])

    return (
        <div className="flex flex-col items-center justify-center bg-gray-900 text-white min-h-screen py-10 px-5">
            <h1 className="text-3xl font-bold mb-5 ">Dungeon Adventure</h1>
            <div className="mt-5 max-w-xl text-center">
                {/* Images */}
                {currentScene === 'Scene1' && (
                    <img
                        src={guardian}
                        alt="Guardian"
                        className="h-48 w-auto mx-auto my-5"
                    />
                )}
                {currentScene === 'Scene2A' && (
                    <img
                        src={gunna}
                        alt="Gunna"
                        className="h-48 w-auto mx-auto my-5"
                    />
                )}
                {currentScene === 'Scene3A' && (
                    <img
                        src={childCandy}
                        alt="Child Candy"
                        className="h-48 w-auto mx-auto my-5"
                    />
                )}
                {currentScene === 'Scene4A' && (
                    <img
                        src={soulja}
                        alt="Soulja"
                        className="h-48 w-auto mx-auto my-5"
                    />
                )}
                {currentScene === 'Scene5A' && (
                    <img
                        src={hole}
                        alt="Hole"
                        className="h-48 w-auto mx-auto my-5"
                    />
                )}
                {currentScene === 'Scene6A' && (
                    <img
                        src={brooklynMirage}
                        alt="Brooklyn Mirage"
                        className="h-48 w-auto mx-auto my-5"
                    />
                )}
                {currentScene === 'Success' && (
                    <img
                        src={converse}
                        alt="Brooklyn Mirage"
                        className="h-48 w-auto mx-auto my-5"
                    />
                )}
                {currentScene === 'GameOver1' && (
                    <img
                        src={holeFail}
                        alt="Hole Fail"
                        className="h-48 w-auto mx-auto my-5"
                    />
                )}
                {currentScene === 'GameOver2' && (
                    <img
                        src={boatFall}
                        alt="Boat Fall"
                        className="h-48 w-auto mx-auto my-5"
                    />
                )}
                {currentScene === 'GameOver3' && (
                    <img
                        src={candySteal}
                        alt="Candy Steal"
                        className="h-48 w-auto mx-auto my-5"
                    />
                )}
                {currentScene === 'GameOver4' && (
                    <img
                        src={souljaFail}
                        alt="Soulja Fail"
                        className="h-48 w-auto mx-auto my-5"
                    />
                )}
                {currentScene === 'GameOver5' && (
                    <img
                        src={dad}
                        alt="Dad"
                        className="h-48 w-auto mx-auto my-5"
                    />
                )}
                {currentScene === 'Complete' && (
                    <img
                        src={completeGif}
                        alt="Completion"
                        className="h-48 w-auto mx-auto my-5"
                    />
                )}

                {/* Scene Text */}
                <p
                    className="whitespace-pre-wrap my-10"
                    dangerouslySetInnerHTML={{
                        __html: scenes[currentScene].text.replace(/\n/g, '<br />'),
                    }}></p>

                {/* Options */}
                <div className="flex flex-wrap justify-center gap-4">
                    {scenes[currentScene].options.map((option, index) => (
                        <button
                            key={index}
                            onClick={() => handleOptionClick(option.nextScene)}
                            className="px-6 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 text-lg">
                            {option.text}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default TextAdventureGame
