import React from 'react'

const DayOne = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
            <div
                className="text-9xl"
                style={{
                    animation: 'fadeInScale 1s ease-out',
                    opacity: 0,
                    transform: 'scale(0.5)',
                    animationFillMode: 'forwards',
                }}>
                🖕
            </div>
            <style>
                {`
				@keyframes fadeInScale {
					0% {
						opacity: 0;
						transform: scale(0.25);
					}
					100% {
						opacity: 1;
						transform: scale(1.5);
					}
				}
        		`}
            </style>
        </div>
    )
}

export default DayOne
