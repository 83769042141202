import React from 'react'

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import Home from './Home'
import AddyMuncher from './screens/AddyMuncher'
import DayOne from './screens/DayOne'
import DungeonAdventure from './screens/DungeonAdventure'
import HappyBirthday from './screens/HappyBirthday'
import MinionCatch from './screens/MinionCatch'
import PriceIsKindaRight from './screens/PriceIsKindaRight'
import RockPaperScissor from './screens/RockPaperScissors'
import TrumpShot from './screens/TrumpShot'

// const dropdownItems = [
// {
//         name: 'HappyBirthday',
//         route: '/happy-birthday',
//         date: new Date('2024-07-28T00:00:00'),
//     },
//     {
//         name: 'AddyMuncher',
//         route: '/addy-muncher',
//         date: new Date('2024-07-22T00:00:00'),
//     },
//     {
//         name: 'TrumpShot',
//         route: '/trump-shot',
//         date: new Date('2024-07-15T00:00:00'),
//     },
//     {
//         name: 'DungeonAdventure',
//         route: '/dungeon-adventure',
//         date: new Date('2024-07-14T00:00:00'),
//     },
//     {
//         name: 'ClickTheMinion',
//         route: '/click-the-minion',
//         date: new Date('2024-07-12T00:00:00'),
//     },
//     {
//         name: 'RockPaperScissors',
//         route: '/rock-paper-scissors',
//         date: new Date('2024-07-11T00:00:00'),
//     },
//     {
//         name: 'DayOne',
//         route: '/day-one',
//         date: new Date('2024-07-09T00:00:00'),
//     },
// ]

function App() {
    return (
        <Router>
            {/* Routes */}
            <div className="">
                <Routes>
                    <Route
                        path="/"
                        element={<Home />}
                    />
                    <Route
                        path="/rock-paper-scissors"
                        element={<RockPaperScissor />}
                    />
                    <Route
                        path="/day-one"
                        element={<DayOne />}
                    />
                    <Route
                        path="/minion-catch"
                        element={<MinionCatch />}
                    />
                    <Route
                        path="/dungeon-adventure"
                        element={<DungeonAdventure />}
                    />
                    <Route
                        path="/trump-shot"
                        element={<TrumpShot />}
                    />
                    <Route
                        path="/addy-muncher"
                        element={<AddyMuncher />}
                    />
                    <Route
                        path="/happy-birthday"
                        element={<HappyBirthday />}
                    />
                    <Route
                        path="/price-guess"
                        element={<PriceIsKindaRight />}
                    />
                </Routes>
            </div>
        </Router>
    )
}

export default App
