import React from 'react'

const gunPng = '/RockPaperScissor/gun.jpeg'
const paperPng = '/RockPaperScissor/paper.png'
const rockPng = '/RockPaperScissor/rock.png'
const scissorPng = '/RockPaperScissor/scissors.png'

const RockPaperScissor = () => {
    const [submitted, setSubmitted] = React.useState(null)

    const handleChoice = (choice) => {
        setSubmitted(choice)
    }

    const handleRestart = () => {
        setSubmitted(null)
    }

    return !submitted ? (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
            <h1 className="text-3xl font-bold mb-4">Let's play Rock-Paper-Scissors</h1>
            <h2 className="text-xl mb-20">Pick your choice</h2>
            <div className="flex justify-center gap-20 mb-20">
                <img
                    src={rockPng}
                    alt="Rock"
                    className="w-36 h-36 cursor-pointer"
                    onClick={() => handleChoice('Rock')}
                />
                <img
                    src={paperPng}
                    alt="Paper"
                    className="w-36 h-36 cursor-pointer"
                    onClick={() => handleChoice('Paper')}
                />
                <img
                    src={scissorPng}
                    alt="Scissors"
                    className="w-36 h-36 cursor-pointer"
                    onClick={() => handleChoice('Scissors')}
                />
            </div>
            {submitted && <p className="text-lg">You picked: {submitted}</p>}
        </div>
    ) : (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white pt-10">
            <h1 className="text-3xl font-bold mb-10">You picked {submitted}...</h1>
            <img
                src={gunPng}
                alt="Gun"
                className="w-52 h-52 mb-10"
            />
            <h1 className="text-3xl font-bold mb-10">You lose!</h1>
            <button
                onClick={handleRestart}
                className="px-16 py-4 text-lg bg-indigo-600 text-white rounded-lg shadow-md hover:bg-indigo-500">
                Restart
            </button>
        </div>
    )
}

export default RockPaperScissor
